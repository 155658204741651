// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-bar-index-js": () => import("./../../../src/pages/bar/index.js" /* webpackChunkName: "component---src-pages-bar-index-js" */),
  "component---src-pages-bar-outdoor-js": () => import("./../../../src/pages/bar/outdoor.js" /* webpackChunkName: "component---src-pages-bar-outdoor-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-event-index-js": () => import("./../../../src/pages/event/index.js" /* webpackChunkName: "component---src-pages-event-index-js" */),
  "component---src-pages-hotel-index-js": () => import("./../../../src/pages/hotel/index.js" /* webpackChunkName: "component---src-pages-hotel-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lokal-conference-js": () => import("./../../../src/pages/lokal/conference.js" /* webpackChunkName: "component---src-pages-lokal-conference-js" */),
  "component---src-pages-lokal-dinner-js": () => import("./../../../src/pages/lokal/dinner.js" /* webpackChunkName: "component---src-pages-lokal-dinner-js" */),
  "component---src-pages-lokal-index-js": () => import("./../../../src/pages/lokal/index.js" /* webpackChunkName: "component---src-pages-lokal-index-js" */),
  "component---src-pages-lokal-party-js": () => import("./../../../src/pages/lokal/party.js" /* webpackChunkName: "component---src-pages-lokal-party-js" */),
  "component---src-pages-lostandfound-index-js": () => import("./../../../src/pages/lostandfound/index.js" /* webpackChunkName: "component---src-pages-lostandfound-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-newsall-js": () => import("./../../../src/pages/newsall.js" /* webpackChunkName: "component---src-pages-newsall-js" */),
  "component---src-pages-nightlife-index-js": () => import("./../../../src/pages/nightlife/index.js" /* webpackChunkName: "component---src-pages-nightlife-index-js" */),
  "component---src-pages-nightlife-newyear-js": () => import("./../../../src/pages/nightlife/newyear.js" /* webpackChunkName: "component---src-pages-nightlife-newyear-js" */),
  "component---src-pages-nightlife-reservedtable-js": () => import("./../../../src/pages/nightlife/reservedtable.js" /* webpackChunkName: "component---src-pages-nightlife-reservedtable-js" */),
  "component---src-pages-policies-index-js": () => import("./../../../src/pages/policies/index.js" /* webpackChunkName: "component---src-pages-policies-index-js" */),
  "component---src-pages-policies-policy-js": () => import("./../../../src/pages/policies/policy.js" /* webpackChunkName: "component---src-pages-policies-policy-js" */),
  "component---src-pages-press-iframe-js": () => import("./../../../src/pages/press/iframe.js" /* webpackChunkName: "component---src-pages-press-iframe-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-restaurant-brunch-js": () => import("./../../../src/pages/restaurant/brunch.js" /* webpackChunkName: "component---src-pages-restaurant-brunch-js" */),
  "component---src-pages-restaurant-chambre-js": () => import("./../../../src/pages/restaurant/chambre.js" /* webpackChunkName: "component---src-pages-restaurant-chambre-js" */),
  "component---src-pages-restaurant-christmastable-js": () => import("./../../../src/pages/restaurant/christmastable.js" /* webpackChunkName: "component---src-pages-restaurant-christmastable-js" */),
  "component---src-pages-restaurant-cocktail-js": () => import("./../../../src/pages/restaurant/cocktail.js" /* webpackChunkName: "component---src-pages-restaurant-cocktail-js" */),
  "component---src-pages-restaurant-dinner-js": () => import("./../../../src/pages/restaurant/dinner.js" /* webpackChunkName: "component---src-pages-restaurant-dinner-js" */),
  "component---src-pages-restaurant-index-js": () => import("./../../../src/pages/restaurant/index.js" /* webpackChunkName: "component---src-pages-restaurant-index-js" */),
  "component---src-pages-restaurant-lunch-js": () => import("./../../../src/pages/restaurant/lunch.js" /* webpackChunkName: "component---src-pages-restaurant-lunch-js" */),
  "component---src-pages-restaurant-uteservering-js": () => import("./../../../src/pages/restaurant/uteservering.js" /* webpackChunkName: "component---src-pages-restaurant-uteservering-js" */),
  "component---src-pages-stureplansgruppen-js": () => import("./../../../src/pages/stureplansgruppen.js" /* webpackChunkName: "component---src-pages-stureplansgruppen-js" */),
  "component---src-templates-bar-template-js": () => import("./../../../src/templates/barTemplate.js" /* webpackChunkName: "component---src-templates-bar-template-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/eventTemplate.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-hotel-template-js": () => import("./../../../src/templates/hotelTemplate.js" /* webpackChunkName: "component---src-templates-hotel-template-js" */),
  "component---src-templates-lokal-template-js": () => import("./../../../src/templates/lokalTemplate.js" /* webpackChunkName: "component---src-templates-lokal-template-js" */),
  "component---src-templates-newstemplate-js": () => import("./../../../src/templates/newstemplate.js" /* webpackChunkName: "component---src-templates-newstemplate-js" */),
  "component---src-templates-nightlife-template-js": () => import("./../../../src/templates/nightlifeTemplate.js" /* webpackChunkName: "component---src-templates-nightlife-template-js" */),
  "component---src-templates-restaurant-template-js": () => import("./../../../src/templates/restaurantTemplate.js" /* webpackChunkName: "component---src-templates-restaurant-template-js" */),
  "component---src-templates-small-talks-template-js": () => import("./../../../src/templates/smallTalksTemplate.js" /* webpackChunkName: "component---src-templates-small-talks-template-js" */)
}

